.priceSliderContainer {
	padding-right: 100px;
}

.partsContainer {
	display: flex;
}

.priceSliderFirstPart {
	width: 52%;
}

.priceSliderSecondPart {
	width: 48%;
	text-align: right;
}

.priceFirstGroup,
.priceSecondGroup {
	display: flex;
	justify-content: space-between;
}

.pinWrapper {
	position: relative;
	top: 6px;
	height: 27px;
	padding-right: 2px;
	padding-left: 23px;
}

.lineContainer {
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 2px;
	padding-left: 23px;
	margin-bottom: 12px;
}

.productItemQuontity {
	position: relative;
}

.quontityWrapper {
	position: absolute;
	top: 0;
	left: -5%;
	display: flex;
	justify-content: space-between;
	width: 110%;
}