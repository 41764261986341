@import "../../../../../style/variables.scss";

.card {
  box-sizing: border-box;
  width: 500px;
  overflow-y: scroll;
  margin: 0 auto;
  @media screen and (max-width: 550px) {
    width: 400px;
  }
  @media screen and (max-width: 450px) {
    width: 320px;
  }
}

.rc-accordion-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.3s;
}
.rc-accordion-toggle.active {
  background-color: $lightWhite;
  color: $hardGrey;
}

.rc-accordion-card {
  border-top: 1px solid $lightWhite;
  overflow: hidden;
  border-left: 1px solid $lightWhite;
  border-right: 1px solid $lightWhite;
}
.rc-accordion-card:last-child {
  margin-bottom: 0;
  border-bottom: 1px solid $lightWhite;
}
.rc-accordion-title {
  color: $lightWhite;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  margin-bottom: 0;
  transition: 0.3s;
  color: $lightWhite;
  padding: 10px 0px 10px 20px;
  &:active {
    text-shadow: $lightWhite 1px 0 10px;
  }
}
.single:active {
  background-color: $lightWhite;
  color: $hardGrey;
}


.rc-accordion-toggle.active .rc-accordion-title {
  color: $hardGrey;
}

.rc-accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
}
.rc-accordion-body div {
  font-size: 16px;
  color: $lightWhite;
  padding: 10px;
  border-radius: 2px;
  a {
    color: #f8f7f0;
  }
}

.rc-accordion-body-title {
  border-bottom: 1px solid $lightWhite;
}

.rc-accordion-body div:hover {
  background-color: $lightWhite;
  color: $hardGrey;
  cursor: pointer;
  a {
    color: $hardGrey;
  }
}

.rc-collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.rc-collapse.show {
  height: auto;
}

.arrow-down {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  fill: $lightWhite;
  transform: rotate(180deg);
}

.rc-accordion-card a {
  text-decoration: none;
  text-transform: uppercase;
}
