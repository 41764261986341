@import "../../style/variables.scss";

.wrapper {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 73px;
  display: flex;
  background-color: $hardGrey;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 54%);
  position: fixed;
  z-index: 100;
  top: 0;
  background: linear-gradient(0deg, rgba(49,47,47,1) 0%, rgba(44,41,42,1) 50%, rgba(49,47,47,1) 100%);
  .container {
    width: $large1620;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 0 30px;
    @media screen and (max-width: 1024px) {
      justify-content: space-between;
      padding: 0 15px;
    }
    @media screen and (max-width: 540px) {
      padding: 0 ;
    }
  }
}
