@import '../../style/variables.scss';

.menu {
  &__wrapper{
    position: absolute;
    width: auto;
    display: block;
    height: auto;
    z-index: 50;
    top: 73px;
    
  }
  &__content {
    position: relative;
    width: 330px;
    height: 445px;
    border: 1px solid $lightWhite;
    border-radius: 2px;
    background: $hardGrey;
    overflow: scroll;
    box-shadow: 0px 0px 7px 1px $hardGrey;
    & > div {
      margin: 0;
      padding: 0;
    }
    &__link {
			position: relative;
        text-decoration: none;
        display: block;
				width: 100%;
				height: 40px;
        padding-left: 17px;
        padding: 10px 0px 10px 17px;
				border-bottom: 1px solid $lightWhite;
				border-left: 1px solid $lightWhite;
        color: $lightWhite;
        font-size: $p;
        text-transform: uppercase;
        transition: all 0.2s;
        &:hover {
					background: linear-gradient(90deg, #ffffff 0, #ffffff 12.5%, #ffffff 25%, #ffffff 37.5%, #eee3e3 50%, #d3cfcf  62.5%, #b9b3b3 75%, #3b3a3a 87.5%, $hardGrey 100%);
          color: $hardGrey;
          padding: 10px 0px 10px 32px;
        }
      }
    }
  }

	.linkContainer {
		display: flex;
		align-items: center;
	}
	.openSubmenu {
		display: inline-block;
		width: 40px;
		height: 40px;
		border-bottom: 1px solid $lightWhite;
		transition: all 0.2s;
	}
	.openSubmenu > svg {
		width: 35px;
		height: 40px;
		fill: $lightWhite;
		stroke: $lightWhite;
	}
	.openSubmenu:hover,
	.openSubmenu:hover > svg {
		background-color: $lightWhite;
		fill: $hardGrey;
		stroke: $hardGrey;
		cursor: pointer;
	}

	.openSubmenuOpened,
	.openSubmenuOpened > svg {
		background-color: $lightWhite;
		fill: $hardGrey;
		stroke: $hardGrey;
	}

  .activeCategory {
    background-color: $lightWhite;
    color: $hardGrey;
    padding: 10px 0px 10px 32px;
  }
  .hidden {
    display: none;
  }
  .sidebar{
    display: block;
    top: 0;
  }
