@import "../../style/variables.scss";

* {
  box-sizing: border-box;
  color: $hardGrey;
}

.modal_tabs {
  display: flex;
  justify-content: center;
}

.modal_tab1 {
  background-color: $lightWhite;
  border-bottom: 2px solid $solidBlack;
  border-right: 2px solid $solidBlack;
  border-radius: 0px 0px 5px 0px;
  width: 320px;
  padding: 15px 0px;
  text-align: center;
  cursor: pointer;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.5);
}

.modal_tab2 {
  background-color: $lightWhite;
  border-bottom: 2px solid $solidBlack;
  border-left: 2px solid $solidBlack;
  border-radius: 0px 0px 0px 5px;

  width: 320px;
  padding: 15px 0px;
  text-align: center;
  cursor: pointer;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.5);
}

.modal_tab_active {
  background-color: $white;
  border: none;
  box-shadow: none;
}

.modalRegistSuccess {
  display: flex;
  flex-direction: column;
  padding: 30px;
  h1 {
    color: $blackBg;
    text-shadow: 1px 1px 2px $hardGrey;
    margin: 0;
  }
  p {
    margin-top: 20px;
  }
}

.modalRegistSuccess_btn {
  margin: 25px auto 0;
  button {
    height: 25px;
    padding: 0 25px;
    border-radius: 7px;
    outline: none;
    border: 2px solid $lightGrey;
    background-color: $lightWhite;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      scale: 1.1;
    }
  }
}

.modalRegistSuccess_send {
  margin: 25px auto 0;
  button {
    height: 25px;
    padding: 0 15px;
    border-radius: 7px;
    outline: none;
    border: 2px solid $lightGrey;
    background-color: $lightGrey;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      scale: 1.1;
    }
  }
}

.form_content {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.form_group {
  display: flex;
  gap: 7px;
  flex-direction: column;
  max-width: 310px;
  label {
    margin: 15px 0 0 10px;
  }
}

/* Hide the default checkbox */
.custom_checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style the custom checkbox */
.custom_checkbox .checkmark {
  position: relative;
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 2px solid $hardGrey;
  border-radius: 3px;
  margin-right: 7px;
}

/* Style the checked state */
.custom_checkbox input[type="checkbox"]:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid $hardGrey;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the label text */
.custom_checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form_input {
  height: 35px;
  padding: 0 10px;
  border: 2px solid $lightGrey;
  border-radius: 7px;
  background-color: $lightWhite;
	font-family: 'Rubik', sans-serif;
  outline: none;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
}

.error_message {
  margin-left: 10px;
  color: red;
}

.input_error {
  border: 1px solid red;
}

.form_submit_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px auto 0;
  button {
    height: 25px;
    padding: 0 15px;
    margin-bottom: 5px;
    border: 2px solid $lightGrey;
    border-radius: 7px;
    background-color: $lightWhite;
    outline: none;
		font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    cursor: pointer;
    &:hover {
      scale: 1.1;
    }
  }
}

.form_hr {
  background-color: $hardGrey;
  width: 350px;
  height: 1px;
  margin: 20px 0 10px -5px;
}

.form_hr2 {
  background-color: $hardGrey;
  width: 320px;
  height: 1px;
  margin: 20px 0 10px -30px;
}
