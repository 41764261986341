@import '../../../style/variables.scss';

.menu {
    &__content{
      & > div {
        margin: 0;
        padding: 0;
      }
      &__link {
				position: relative;
				display: block;
				width: 100%;
				height: auto;
				padding: 10px 0px 10px 17px;
				padding-left: 17px;
				border-bottom: 1px solid $lightWhite;
				border-left: 1px solid $lightWhite;
				color: $lightWhite;
				font-size: $p;
          text-decoration: none;
          text-transform: uppercase;
          transition: all 0.2s;
          &:hover {
						background: linear-gradient(90deg, #ffffff 0, #ffffff 30%, #ffffff 40%, #ffffff 50%, #eee3e3 70%, #d3cfcf  75%, #b9b3b3 80%, #3b3a3a 95%, $hardGrey 100%);
            color: $hardGrey;
            padding: 10px 0px 10px 32px;
          }
        }
    }
  } 
  
  .hidden {
    display: none;
  }
  .sidebar{
    display: block;
    top: 0;
  }

	.subContentItemContainer {
		display: flex;
		align-items: center;
	}

	.subContentDevider {
		width: 100%;
		max-width: 6px;
		height: 40px;
		border-bottom: 1px solid $lightWhite;
	}

	.subContent {
		padding-right: 35px !important;
		padding-left: 13px !important;
		background: $hardGrey;
		overflow: hidden;
	}