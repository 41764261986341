.orderDetails {
  
  &__grid {
    &_header {
      display: grid;
      grid-template-columns: 70px 188px 566px 143px 135px 160px;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      border-bottom: 1px solid black;
      width: 1350px;
      margin: auto;
      margin-bottom: 25px;
      font-weight: 600;
      @media screen and (max-width: 1415px) {
        grid-template-columns: 70px 188px 475px 143px 135px 160px;
        width: 1245px;
      }
      @media screen and (max-width: 1310px) {
        grid-template-columns: 40px 120px 296px 143px 135px 160px;
        width: 1024px;
      }
    }
    &_header_col1 { 
      grid-area: 1 / 1 / 2 / 2;
      max-width: 70px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1310px) {
        grid-area: 1 / 1 / 2 / 2;
      } 
    }
    &_header_col2 { 
      grid-area: 1 / 2 / 2 / 3;
      max-width: 188px;
      display: flex;
      justify-content: center; 
    }
    &_header_col3 { 
      grid-area: 1 / 3 / 2 / 4;
      max-width: 584px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1415px) {
        width: 475px;
      }
      @media screen and (max-width: 1310px) {
        width: 296px;
      } 
    }
    &_header_col4 { 
      grid-area: 1 / 4 / 2 / 5;
      max-width: 143px;
      display: flex;
      justify-content: center;
    }
    &_header_col5 { 
      grid-area: 1 / 5 / 2 / 6;
      max-width: 135px;
      display: flex;
      justify-content: center;
    }
    &_header_col6 { 
      grid-area: 1 / 6 / 2 / 7;
      max-width: 160px;
      display: flex;
      justify-content: center;
    }
    
    &_colName {
      font-size: 16px;
      text-transform: uppercase;

    }
    
  }
}