//color
$solidBlack: #000; // hover...
$blackBg: #2c2929; // navbar, footer
$hardGrey: #333030; // text, title
$lightWhite: #f8f7f0; // text, title
$white: #fff; // bg...
$lightGrey: #bcbab5; //border...
$searchPlaceholder: #a8a7a2; //search text placeholder 
$tableBorder: #929191;
// font size
$h1: 30px;
$h2: 24px;
$p: 16px; //(main-price)20px 
$p20: 20px; //(second-price)16px 
$p10: 10px; //(stok-status) 10px 

//container size
$large1620: 1620px;
$medium1280: 1280px;
$tablet1014: 1024px;




