@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ruda:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ruda:wght@400;500;600;700;800;900&display=swap');
@import "./style/variables.scss";

#root,
html {
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  background: #f2f2f2;
  font-family: "Rubik", sans-serif;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

p {
  margin: 0;
  padding: 0;
}
h2 {
  font-size: 18px;
  padding: 0;
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(219, 7, 7, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px dashed dimgrey;
  animation: rotate 1s infinite linear;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

@keyframes rotate {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1.4);
  }
}
