@import "../../style/variables.scss";

.mainWrapper {
  padding-top: 10px;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 15px 15px;

		& > ul {
			font-family: 'Ruda', sans-serif;
		}

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

	.bottomWrapper > ul {
		font-family: 'Ruda', sans-serif;
	}

	.breadcrumbsContainer {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 5px;
	}

	.title {
		display: inline-block;
    font-size: 24px;
    font-weight: 400;
		line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    @media screen and (max-width: 1024px) {
      margin-bottom: 30px;
    }
  }

	.line {
		border-bottom: 1px solid $solidBlack;
		margin-top: 0;
		margin-bottom: 15px;
	}

  .wrapperListCards {
    margin-top: -70px;
    @media screen and (max-width: 630px) {
      margin-top: 0px;
    }
  }
}
