.partners_bg {
  position: absolute;
  top: 73px;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/Front-BG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.container_partners {
  position: relative;
  padding-bottom: 5px;
  .partners_empty {
    height: 100vh;
  }
  .partners_image {
    position: absolute;
    top: 0;
    left: -30px;
    background-image: url("../../assets/Front-BG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: calc(100% + 60px);
    margin: 0 auto;
    border: none;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.4);
    @media screen and (max-width: 540px) {
      width: calc(100% + 35px);
    }
  }
  .partners_logo {
    position: absolute;
    top: 25vh;
    left: 0px;
    background-image: url("../../assets/logo&slogan.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 230px;
    width: 100%;
    margin: 0 auto;
  }
  .partners_text {
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    text-transform: uppercase;
    top: 375px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: 540px) {
      font-size: 16px;
    }
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
  }
  .partners_arrow {
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    top: 405px;
    width: 25px;
    right: 0;
    margin: auto;
    @media screen and (max-width: 540px) {
      width: 18px;
      height: 18px;
    }
  }
}

.anchor {
  position: absolute;
  margin-top: -60px;
}

.title {
  margin-top: 40px;
  padding: 0 20px 20px;
  font-weight: 700;
  font-size: 25px;
  line-height: 33px;
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 25px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -0px;
    left: 0;
    width: 32%;
    border-bottom: 1px solid black;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  }
}

.wrapperText {
  margin-left: 45px;
  .text {
    padding: 30px 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 33px;
    font-family: "Arial";
    position: relative;
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
      font-size: 13px;
      line-height: 25px;
    }

    &:not(:last-of-type)::after {
      content: "";
      position: absolute;
      bottom: -0px;
      left: 0;
      width: 22%;
      border-bottom: 1px solid black;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    }
  }

  .image {
    float: right;
    width: 26%;

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
    @media screen and (min-width: 1300px) {
    display: flex;
  }

}

.imageMobile {
  display: none;

  @media screen and (max-width: 1000px) {
    display: block;
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    max-width: 300px;
  }
}

.title::before,
.text::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: black;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
}
.content_wrapper {
  height: 87vh;
  @media screen and (max-width: 1024px) {
    height: auto;
  }
}