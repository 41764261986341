@import "../../../style/variables.scss";
.modalCard {
  position: absolute;
  top: 73px;
  right: 11%;
  z-index: 1;
  display: block;
  width: 565px;
  border: 1px solid #9f9e9e;
  border-radius: 4px;
  background: white;
  & > ul {
    margin-bottom: 8px;
    max-height: 333px;
    overflow: auto;
    padding: 0 10px;
  }
}

.wrapperShoppingCard {
  position: relative;
  display: flex;
  height: 73px;
  width: 73px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    svg {
      fill: $hardGrey;
    }
    background-color: $lightWhite;
  }
}

.wrapperShoppingCardOpen {
  background: $lightWhite;
  position: relative;
  display: flex;
  height: 73px;
  width: 73px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $lightWhite;
}

.shoppingCard {
  width: 45px;
  height: 45px;
  fill: $lightWhite;
  @media screen and (max-width: 540px) {
    width: 35px;
    height: 29px;
  }
}

.shoppingCardOpen {
  width: 45px;
  height: 45px;
  fill: $hardGrey;
  @media screen and (max-width: 540px) {
    width: 35px;
    height: 29px;
  }
}

.number {
  position: absolute;
  right: 3px;
  bottom: 10px;
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 100%;
  background: #f34951;
  color: $lightWhite;
	font-family: 'Ruda', sans-serif;
  font-weight: 600;
  text-align: center;
  & > p {
    color: $lightWhite;
  }
}

.numberOpen {
  position: absolute;
  right: 3px;
  bottom: 10px;
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 100%;
  background: #f34951;
  color: black;
	font-family: 'Ruda', sans-serif;
  font-weight: 600;
  text-align: center;
}

.textAlert {
  padding: 15px 20px 5px 20px;
}

.more {
  display: flex;
  margin: auto;
}

.wrapperPrice {
  border-top: 1px solid #9f9e9e;
  margin-bottom: 5px;
	text-align: center;
}

.price {
	margin-bottom: 5px;
	text-align: center;
}

.topLine {
	width: 33%;
	border-bottom: 1px solid black;
	margin-right: auto;
	margin-left: auto;
}

.priceContainer {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 40%;
}

.priceContainer > p {
	margin-top: 2px;
	font-family: 'Ruda', sans-serif;
}

.priceDevider {
	display: flex;
	flex-direction: column;
	height: 21px;
}

.priceLine {
	height: 49%;
	border-right: 1px solid black;
}

.deviderItem {
	width: 1px;
	height: 51%;
}

.button {
  display: block;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 0;
  border-top: 1px solid #9f9e9e;
  text-decoration: none;
  &:hover {
    background-color: $lightWhite;
		cursor: pointer;
  }
}
