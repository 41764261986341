.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.container svg {
  width: 27px;
  height: 27px;
  fill: black;
}

.pinContainer {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.inputRange {
  width: 100%;
  margin: 0;
  opacity: 0;
}

.inputRange::-webkit-slider-runnable-track {
  height: 100%;
  cursor: pointer;
}

.inputRange::-webkit-slider-thumb {
  opacity: 0;
}

// .middleButton {
// 	position: absolute;
// 	z-index: 3;
// 	top: 30%;
// 	left: 46%;
// 	width: 34px;
// 	height: 34px;
// }