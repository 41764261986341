@import "../../../style/variables.scss";

* {
  box-sizing: border-box;
  color: $hardGrey;
}

.search_block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 157px;
  width: 100%;
  margin-right: 34px;
  @media screen and (max-width: 1280px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1024px) {
    margin-right: 15px;
  }
  .search_img {
    width: 25px;
    height: 25px;
    fill: $lightWhite;
    transform: rotate(90deg);

    @media screen and (min-width: 1024px) and (max-width: 1080px) {
      display: none;
    }
    @media screen and (max-width: 570px) {
      display: none;
    }
  }
	.search_cross_container {
		position: absolute;
		top: 13%;
		left: 94%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border: 2px solid #7c7b78;
		border-radius: 50%;
		background-color: $lightWhite;
		&:hover {
			cursor: pointer;
		}
	}
	.search_cross {
		width: 12px;
		height: 12px;
		fill: #7c7b78;
		transform: rotate(90deg);

		@media screen and (min-width: 1024px) and (max-width: 1080px) {
			display: none;
		}
		@media screen and (max-width: 570px) {
			display: none;
		}
	}
  .input_block {
    width: 157px;
    height: 40px;
    padding: 0px 10px 0px;
    border: 1px solid $lightGrey;
    border-radius: 7px;
    margin-left: 15px;
    background-color: $lightWhite;
		font-family: 'Rubik', sans-serif;
    font-size: 18px;
    outline: none;
    transition: width 0.3s linear;
    &_wide {
      min-width: 157px;
      width: 100%;
      margin-left: 45px;
      @media screen and (max-width: 1330px) {
        margin-left: 10px;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 30px;
      }
    }
    &::placeholder {
      color: $searchPlaceholder;
      opacity: 0.7;
      font-size: 18px;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 540px) {
    width: 320px;
    margin-right: 0px;
  }
  @media screen and (max-width: 460px) {
    width: 270px;
  }
  @media screen and (max-width: 430px) {
    width: 230px;
  }
  @media screen and (max-width: 380px) {
    width: 200px;
  }
}

.hidden {
  display: none;
}

.search_results {
  position: absolute;
  top: 56px;
  right: -5px;
  width: 94%;
  max-height: 332px;
  z-index: 1;
  background-color: $white;
  border: 2px solid $lightGrey;
  border-radius: 3px;
  overflow-y: auto;
  margin: 0px;
  padding: 0px;

  .search_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-bottom: 1px solid $lightGrey;
    list-style: none;
    height: fit-content;
    cursor: pointer;
    @media screen and (max-width: 1273px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: 1233px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: row;
    }
    @media screen and (max-width: 730px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: 540px) {
      flex-direction: column;
    }
    .search_item_block {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .search_item_image {
      width: 50px;
      height: 50px;
      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
    .price_wrapper {
      text-align: end;
      @media screen and (max-width: 1273px) {
        display: flex;
      }
      @media screen and (max-width: 1233px) {
        display: flex;
      }
      @media screen and (max-width: 1024px) {
        display: block;
      }
      @media screen and (max-width: 730px) {
          display: flex;
      }
      @media screen and (max-width: 540px) {
        display: flex;
      }
      p {
        margin: 5px;
      }
    }
    span {
      background-color: $lightGrey;
      padding: 1px 5px;
      border-radius: 2px;
    }
  }
  @media screen and (max-width: 1500px) {
    right: 0px;
    width: 91%;
  }

  @media screen and (max-width: 1463px) {
    right: 0px;
    width: 90%;
  }

  @media screen and (max-width: 1024px) {
    right: 0px;
    width: 95%;
  }

  @media screen and (max-width: 950px) {
    right: 0px;
    width: 94%;
  }

  @media screen and (max-width: 860px) {
    right: 0px;
    width: 94%;
  }

  @media screen and (max-width: 769px) {
    right: 0px;
    width: 92%;
  }
  @media screen and (max-width: 729px) {
    right: 0px;
    width: fit-content;
    max-width: 87%;
  }

  @media screen and (max-width: 540px) {
    right: 0px;
     width: fit-content;
     max-width: 360px;
    
  }
  @media screen and (max-width: 460px) {
    right: 0px;
     width: fit-content;
     max-width: 360px;
    
  }
  @media screen and (max-width: 430px) {
     width: fit-content;
     max-width: 360px;
  }

  @media screen and (max-width: 380px) {
    right: 0px;
     width: fit-content;
     max-width: 360px;
    
  }
}

.search_results div:hover {
  background-color: $lightWhite;
}

.search_results::-webkit-scrollbar {
  width: 5px;
}
.search_results::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.search_results::-webkit-scrollbar-thumb {
  background-color: rgb(156, 156, 156);
}
