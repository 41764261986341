@import "../../../../style/variables.scss";

.productItem_menu {
  display: flex;
  align-items: center;
	justify-content: space-between;
  gap: 30px;
	padding-top: 10px;
	margin-bottom: 5px;
  text-transform: uppercase;
  a {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 670px) {
    flex-direction: column;
    gap: 10px;
  }
}

.devider {
	border-bottom: 1px solid #000000;
	margin-bottom: 25px;
}

.productItem_block {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.productItem_imgBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .productItem_slide {
    height: 500px;
    width: 100px;
    background-color: aqua;
  }
  .productItem_image {
    width: 100%;
    max-width: 590px;
    height: 500px;
    @media screen and (max-width: 620px) {
      height: 400px;
    }
    @media screen and (max-width: 450px) {
      height: 300px;
    }
  }
}

.zoomed {
  transform: translate(70%, 28%) scale(1.5);
  z-index: 1;
  @media screen and (max-width: 1024px) {
    transform: translate(0%, 20%) scale(1.4);
    z-index: 1;
  }
}

.titleContainer {
	position: relative;
	right: 4%;
}

.productItem_title_item {
	margin-bottom: 5px;
	text-align: left;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.productItem_title_container {
	display: inline-block;
}

.productItem_btn_edit {
  border: none;
  width: 28px;
  height: 27px;
  background-color: white;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}

.productItem_btn_edit_none {
  display: none;
}

.productItem_h {
	display: inline-block;
  margin-top: 0px;
	margin-bottom: 0px;
  font-size: 26px;
  letter-spacing: 0.8px;
  @media screen and (max-width: 700px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 510px) {
    margin: 0px;
    font-size: 22px;
    text-align: center;
  }
}

.productItem_input {
  width: 100%;
  max-width: 563px;
  margin: 26px 0;
  height: 38px;
  font-size: 26px;
  font-family: "Lato", sans-serif;
  padding: 16px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  @media screen and (max-width: 870px) {
    height: 32px;
  }
}

.productItem_content_main {
  flex-basis: 50%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 562px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
}

.productItem_content {
  display: flex;
  flex-direction: column;
  gap: 35px;
  flex: 50%;
  .productItem_price {
    display: flex;
    flex-direction: column;
    span {
      font-size: 28px;
      border-bottom: 1px solid $hardGrey;
    }
    span > span {
      margin-left: 3px;
      font-size: 20px;
      border-bottom: none;
    }
    @media screen and (max-width: 1024px) {
      align-items: center;
    }
  }
  .productItem_info {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-top: 3px;
    @media screen and (max-width: 1210px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: 1024px) {
      align-items: center;
    }
  }

  .productItem_btn_ask {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    height: 25px;
    outline: none;
    border: 2px solid $lightGrey;
    background-color: $lightWhite;
    border-radius: 5px;
    text-transform: uppercase;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    cursor: pointer;
 
    &:hover {
      background-color: $hardGrey;
      color: $lightWhite;
      border: none;
      box-shadow: none;
    }
  }
  @media screen and (max-width: 1024px) {
    align-items: center;
  }
}

.productCountContainer {
	position: relative;
	right: 4%;
}

.quantityContainer {
	display: inline-block;
	width: 110px;
	margin-top: 5px;
}

.productItem_sum {
  span {
    font-size: 24px;
  }
	.productItem_sum_container {
		position: relative;
		right: 4%;
		display: inline-block;
		width: 50%;
		text-align: center;
	}
	.productItem_sum_title {
		display: inline-block;
	}
	.productItem_sum_lines_wrapper {
		text-align: center;
	}
	.productItem_sum_lines_container {
		display: inline-block;
		width: 50%;
	}
	.productItem_sum_top_line_container {
		display: flex;
		align-items: center;
		width: 100%;
	}
	.productItem_sum_top_line,
	.productItem_sum_bottom_line {
		width: 50%;
		height: 7px;
	}
	.first {
		border-right: 1px solid black;
	}
	.productItem_sum_middle_line {
		border-bottom: 1px solid black;
	}
	.productItem_sum_bottom_line_container {
		display: flex;
		align-items: center;
	}
	.right {
		border-right: 1px solid black;
	}
	.left {
		border-left: 1px solid black;
	}
	.productItem_btn_sum_container {
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
  .productItem_btn_sum {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 33px;
    padding-bottom: 1px;
    border: 1px solid $hardGrey;
    border-radius: 3px;
    margin-bottom: 5px;
    background-color: $lightWhite;
		font-family: 'Ruda', sans-serif;
    outline: none;
    text-transform: uppercase;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
}

.productItem_btn_sum_number {
	max-width: 120px;
	overflow: hidden;
}
.productItem_btn_sum_symbol {
	width: 16px;
}

.btnAddToContainer {
	height: 55px;
}

.productItem_addTo {
  width: 100%;
  max-width: 240px;
  height: 50px;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: $hardGrey;
  color: $lightWhite;
	font-family: 'Rubik', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
}

.active:active {
	scale: 0.9;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
}

.unactive {
	opacity: 50%;
}

.productItem_block_image {
  padding-right: 90px;
  flex: 50%;
  position: relative;
  .productItem_block_title {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  @media screen and (max-width: 1280px) {
    width: 80%;
    border-right: none;
    padding-right: 25px;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
    border-right: none;
    padding-right: 0px;
  }
}

.productItem_btn_cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 38px;
  height: 38px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 870px) {
    height: 35px;
  }
}

.btn_cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 2px solid $hardGrey;
  border-radius: 50%;
  background-color: $lightWhite;
  svg {
    width: 9px;
    height: 9px;
  }
}

.productItem_btn_save {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 38px;
  height: 38px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  svg {
    width: 20px;
    height: 25px;
  }
  @media screen and (max-width: 870px) {
    height: 35px;
  }
}

.productItem_btn_setting {
  position: absolute;
  top: 72px;
  right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 38px;
  height: 38px;
  outline: none;
  border: none;
  background-color: #f8f7f0;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  &:hover {
    scale: 1.1;
  }
  svg {
    width: 35px;
    height: 35px;
  }
}

.productItem_btn_zoom {
  position: absolute;
  top: -38px;
  right: -77px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 38px;
  height: 38px;
  outline: none;
  border: none;
  background-color: $white;
  transform: rotate(90deg);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  svg {
    fill: $lightGrey;
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 1280px) {
    right: -40px;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.productItem_is {
  color: #56935f;
  font-weight: 600;
}

.productItem_no {
	color: #bcbab5;
	font-weight: 600;
}

.productItem_basket_btn {
	position: relative;
	right: 4%;
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
  p {
    color: $lightGrey;
  }
}

.productItem_favourite {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @media screen and (min-width: 562px) and (max-width: 1024px) {
    position: absolute;
    right: 15px;
  }

  .heart {
    width: 28px;
    height: 28px;
		&:active {
			scale: 0.9;
		}
  }
  .heartHover {
    width: 28px;
    height: 28px;
    opacity: 0.8;
		&:active {
			scale: 0.9;
		}
  }
  .productItem_heart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    p {
      font-size: 14px;
      color: $lightGrey;
      font-weight: 600;
    }
  }
}

.productItem_sku {
	height: 25px;
	padding: 4px;
	border-radius: 3px;
	background-color: $lightGrey;
	font-family: 'Arial';
	font-size: 16px;
}

.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 55%;
    height: 80%;
  }
}
