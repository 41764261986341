@import '../../../../style/variables.scss';
.cart {
  &__wrapper{
    width: 100%;
    overflow: hidden;
  }
  &__table{
    width: 1350px;
    margin: auto;
    border-collapse: collapse;
    max-height: 70px;
    @media screen and (max-width: 1415px) {
      width: 1245px;
    }
    @media screen and (max-width: 1310px) {
      width: 1024px;
    }
    @media  screen and (max-width: 1023px) {
      width: 100%; 
    }
    &_delete {
      border: none;
    }
    
    &_summaryTitle {
      @media screen and (min-width: 540px) {
        background-color: $blackBg;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $tableBorder; 
      }
      @media screen and (max-width: 540px) {
        text-align: center;
        background-color: $blackBg;
        height: 36px;
        border: 2px solid $tableBorder;
        border-bottom: 1px solid $tableBorder;
        border-top: 1px solid $tableBorder;
      }
      .summaryTitle {
        color: $lightWhite;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
    &_summaryWrapper {
      background-color: $lightGrey;
      height: 70px;
      padding: 0;
      text-align: center;
      border: 1px solid $tableBorder;
      @media screen and (max-width: 540px) {
        border: 2px solid $tableBorder;
        border-top: 1px solid $tableBorder;
      }
      & > div:nth-child(1) {
        border-bottom: 1px solid $tableBorder;
      }
      & > div {
        height: 50%;
      }
      .nationalSummary {
        height: 100%;
				font-family: 'Ruda', sans-serif;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.45;
        text-decoration: underline;
      }
      .internationalSummary {
        height: 100%;
				font-family: 'Ruda', sans-serif;
        font-weight: 600;
        line-height: 2;
        text-decoration: underline;
      }
    }
  }
}
.borderNone {
  border: none;
}