@import "../../../../style/variables.scss";

// Slider

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;

  span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
}

.indicators {
  cursor: pointer;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  /* top: 0; */
  left: 0;
  right: 0;
  margin-top: -40px;
  overflow: hidden;
  gap: 8px;

  &.active {
    color: #fff;
    background: #666;
  }
}

.productSlider {
  min-width: 280px;
  flex-basis: 75%;
  height: 375px;
  position: relative;
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
    background: #fff !important;
    width: 12px;
    height: 12px;
    @media screen and (max-width: 540px) {
      height: 6px;
      width: 6px;
    }
  }

  .react-slideshow-container .default-nav {
    background: rgba(42, 42, 42, 0.6) !important;
    width: 50px;
    height: 50px;

    > svg {
      fill: #ffffffa3;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-basis: 100%;
  }
}

.react-slideshow-container .default-nav:last-of-type {
  margin-left: -30px;
  margin-right: 15px;

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.react-slideshow-container .default-nav:first-of-type {
  margin-right: -30px;
  border-right: 0;
  border-top: 0;
  margin-left: 15px;

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.sliderCard {
  width: 100%;
  min-width: 0;
  margin: auto;
  margin-top: 40px;
  height: auto;
  .react-slideshow-container .default-nav {
    width: 40px;
    height: 40px;
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
}

.react-slideshow-container + ul.indicators li {
  height: 12px;
  width: 12px;
}

.slider_position {
  justify-content: flex-end;
}

// slider ImageGallery

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
  polyline {
    color: $lightWhite !important;
  }
 
}

.image-gallery-bullets .image-gallery-bullet {
  background-color: $lightGrey !important;
  border: none !important;
  box-shadow: none !important;
}

.image-gallery.fullscreen-modal {
  z-index: 100 !important;
  background: #00000050!important;
  backdrop-filter: blur(5px) ;
}

.image-gallery-content.fullscreen {
  background: #00000050!important;
  backdrop-filter: blur(5px) ;
}