@import "../../style/variables.scss";

.container__sidebar_slider {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  height: 435px;
  align-items: flex-end;

  @media screen and (max-width: 1024px) {
    justify-content: center;
    height: 392px;
  }

  @media screen and (max-width: 540px) {
    height: 380px;
  }
}
