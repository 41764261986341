@import "../../style/variables.scss";

.partners_bg {
  position: absolute;
  top: 73px;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/Front-BG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container_partners {
  position: relative;
  .partners_empty {
    height: 100vh;
  }
  .partners_image {
    position: absolute;
    top: 0;
    left: -30px;
    background-image: url("../../assets/Front-BG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: calc(100% + 60px);
    margin: 0 auto;
    border: none;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.4);
    @media screen and (max-width: 540px) {
      width: calc(100% + 35px);
    }
  }
  .partners_logo {
    position: absolute;
    top: 25vh;
    left: 0px;
    background-image: url("../../assets/logo&slogan.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 230px;
    width: 100%;
    margin: 0 auto;
  }
  .partners_text {
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    text-transform: uppercase;
    top: 375px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: 540px) {
      font-size: 16px;
    }
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
  }
  .partners_arrow {
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    top: 405px;
    width: 25px;
    right: 0;
    margin: auto;
    @media screen and (max-width: 540px) {
      width: 18px;
      height: 18px;
    }
  }
}

.anchor {
  position: absolute;
  margin-top: -60px;
}

.title {
  margin-top: 40px;
  padding: 0 20px 20px;
  font-weight: 700;
  font-size: 25px;
  line-height: 33px;
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 25px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -0px;
    left: 0;
    width: 32%;
    border-bottom: 1px solid $hardGrey;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  }
}

.wrapperText {
  margin-left: 45px;
  .text {
    padding: 25px 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 33px;
    position: relative;
    text-transform: uppercase;
    font-family: "Arial";
    @media screen and (max-width: 600px) {
      font-size: 13px;
      line-height: 25px;
    }
  }
}

.hr {
  position: relative;
  height: 1px;
  max-width: 95%;
  background-color: $hardGrey;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 1px;
    height: 30px;
    background-color: $hardGrey;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  }
}

.title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: $hardGrey;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
}

.text::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 125%;
  background-color: $hardGrey;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
}
.partners_content {
  height: 87vh;
}
.message_block {
  position: relative;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  .textarea {
    width: 1300px;
    max-width: 100%;
    height: 190px;
    border-radius: 5px;
    padding: 11px 40px 11px 15px;
    font-size: 18px;
    font-family: "Arial";
    resize: none;
    outline: none;
    border: 2px solid #bcbab5;
    background-color: #f8f7f0;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    &::placeholder {
      font-size: 18px;
      font-family: "Arial";
      padding: 4px;
    }
    @media screen and (max-width: 540px) {
      font-size: 16px;
      height: 120px;
    }
  }
  .btn_send {
    text-transform: uppercase;
    width: 315;
    padding: 5px 45px;
    border-radius: 8px;
    border: 2px solid #bcbab5;
    background-color: #f8f7f0;
    font-weight: bold;
    color: #333030;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    cursor: pointer;
  }
  .paper_clip {
    position: absolute;
    top: 11px;
    right: 15px;
    width: 30px;
    height: 30px;
  }
  .input_file {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    width: 315;
    padding: 5px 45px;
    border-radius: 8px;
    border: 2px solid #bcbab5;
    background-color: #f8f7f0;
    font-weight: bold;
    color: #333030;
    text-align: center;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    @media screen and (max-width: 540px) {
      width: 100%;
      font-size: 12px;
    }
  }
}
.success {
  color: green;
}