@import "../../../style/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  @media screen and (max-width: 782px) {
    margin-bottom: 10px;
  }

  .title {
    font-size: 18px;
    margin-right: 10px;
    font-weight: 600;
  }
  .wrapperSelect {
    position: relative;
    z-index: 1;
		select:hover {
			cursor: pointer;
		}
    & > svg {
      position: absolute;
      width: 15px;
      height: 15px;
      z-index: 2;
      right: 4px;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(180deg);
      fill: #7C7B78;
    }
  }

  .select {
    width: 200px;
    padding: 5px 2px;
    border: 1px solid #bcbab5;
    border-radius: 4px;
		background-image: url('../../../assets/svg/downArrow.svg');
		appearance: none;
  	background-repeat: no-repeat;
		background-size: 30px 30px;
  	background-position: right center;
    background-color: $lightWhite;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.54);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &:focus {
      outline: 1px solid #bcbab5;
      border-color: transparent;
    }
    &::-ms-expand {
      display: none;
    }
  }
  
} /* styles.module.css */
