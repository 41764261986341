@import '../../../../style/variables.scss';




.orderDetails {
  td { 
    @media screen and (min-width: 1024px) {
      border: 1px solid $tableBorder;
      border-bottom: 2px solid $tableBorder;
    }
    @media screen and (max-width: 1023px) {
      border: 1px solid $tableBorder;
      border-bottom: 2px solid $tableBorder;
    }
  }
  
  th {
    border-top: 2px solid $tableBorder;
    border-bottom: 1px solid $tableBorder;
    border-right: 1px solid $tableBorder;
    background-color: $lightGrey;
  }
  th:first-of-type {
    border-left: 2px solid $tableBorder;
    border-right: 1px solid $tableBorder;
  }
  th:last-of-type {
    border-right: 2px solid $tableBorder;
  }
  &__table{
    &_number{
      width: 70px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      @media screen and (max-width: 1310px) {
        width: 40px;
      }
      @media screen and (max-width: 1023px) {
        width: 10%;
        border-left: 2px solid $tableBorder;
      }
      @media screen and (max-width: 540px) {
        height: 40px;
        border-left: 2px solid $tableBorder;
        border-right: 2px solid $tableBorder;
      }
    }
    &_picture {
      width: 188px;
      text-align: center;
      height: 70px;
      @media screen and (max-width: 1310px) {
        width: 120px;
      }
      @media screen and (max-width: 1023px) {
        width: 25%;
      }
      @media screen and (max-width: 540px) {
        height: 120px;
        border-left: 2px solid $tableBorder;
        border-right: 2px solid $tableBorder;
      }
      & > div {
        background-size: contain;
        height: 65px;
        background-repeat: no-repeat;
        background-position: center;
        min-width: 118px;
        @media screen and (max-width: 540px) {
          height: 120px;
        }
      }
    }
    &_title { 
      width: 566px;
      padding: 0 18px;
      min-width: 171px;
      @media screen and (max-width: 1310px) {
        width: 296px;
      }
      @media screen and (max-width: 1023px) {
        width: 55%;
      }
      @media screen and (max-width: 540px) {
        height: 70px;
        border-left: 2px solid $tableBorder;
        border-right: 2px solid $tableBorder;
      }
      &_row1 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        margin-top: -2px;
        h2 {
          text-transform: capitalize;
          font-size: 20px;
          font-weight: bold;
          @media screen and (max-width: 1310px) {
            max-width: 195px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .favorite {
          width:25px;
          height: auto;
          display: inline-block;
          cursor: pointer;
          .activeFavorite {
            width: 25px;
            height: 25px;
            display: inline-block;
            cursor: pointer;
            background-image: url('../../../../assets/svg/black_heart.svg');
            background-position: center;
            background-repeat: no-repeat;
            }
            .hoverFavorite {
              width:25px;
              height: 25px;
              display: inline-block;
              cursor: pointer;
              background-image: url('../../../../assets/svg/heart.svg');
              background-position: center;
              background-repeat: no-repeat;
              &:hover {
                background-image: url('../../../../assets/svg/black_heart.svg');
                opacity: 0.8;
              }
            }
        }
      }
      &_row2 {
        display: flex;
        justify-content: flex-end;
        .product_sku {
          background-color: $lightGrey;
          border-radius: 2px;
          color: black;
          padding: 2px 5px;
          font-weight: 600;
          & > span {
            text-transform: uppercase;
          }
        }
      }
    }
    &_price {
      width: 143px;
      height: 70px;
      padding: 0;
      text-align: center;
      @media screen and (max-width: 540px) {
        height: 70px;
        border-left: 2px solid $tableBorder;
        width: 50%;
      }
      & > div:nth-child(1) {
        border-bottom: 1px solid $lightGrey;
      }
      & > div {
        height: 50%;
      }
      .nationalPrice {
        height: 100%;
        line-height: 1.45;
        font-size: 22px;
        font-weight: bold;
      }
      .internationalPrice {
        height: 100%;
        line-height: 2;
      }
    }
    &_quantity{
      width: 135px;
      height:70px;
      padding: 0;
      text-align: center;
      @media screen and (max-width: 540px) {
        height: 70px;
        border-right: 2px solid $tableBorder;
        width: 50%;
      }
      & > div:nth-child(1) {
        border-bottom: 1px solid $lightGrey;
      }
      & > div {
        height: 50%;
      }
      & > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        max-width: 120px;
        margin: auto;
        .inputDisable {
          position: absolute;
          width: 88px;
          height: 25px;
          display: inline-block;
          z-index: 3;
          left: 0px;
          @media screen and (max-width: 1024px) {
            left: 0px;
          }
        }
        .navigationWrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding-right: 4px;
          .itemUp {
            width: 10px;
            height: 12px;
            background-image: url('../../../../assets/svg/up-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
          }
          .itemDown {
            width: 10px;
            height: 12px;
            background-image: url('../../../../assets/svg/up-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(180deg);
            cursor: pointer;

          }
        }
      }
      .minQuantity {
        font-size: 12px;
        font-weight: 900;
        height: 100%;
      }
      input { 
        background-color: $lightWhite;
        border-radius: 5px;
        border: 2px solid $lightGrey;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
        width: 88px;
        height: 25px;
        padding: 0 10px;
        font-weight: 600;
        margin-right: 5px;
        outline: none;
      }
      p { 
        font-size: 16px;
        font-weight:600;
      }
    }
    &_summaryPrice {
      width: 160px;
      height: 70px;
      padding: 0;
      text-align: center;
      @media screen and (max-width: 540px) {
        height: 70px;
        border-left: 2px solid $tableBorder;
        border-right: 2px solid $tableBorder;
      }
      & > div:nth-child(1) {
        border-bottom: 1px solid $lightGrey;
      }
      & > div {
        height: 50%;
        background-color: #f2f2f2;
      }
      .nationalSummary{
        height: 100%;
        line-height: 1.45;
        font-size: 22px;
        font-weight: bold;
      }
      .internationSummary {
        height: 100%;
        line-height: 2;
      }
    }
    &_basket {
      @media screen and (min-width: 1023px) {
        border: none;
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          height: 1px;
          width: 40px;
          top: -0.5px;
          left: 0;
          background-color: $tableBorder;
        }
        &:after {
          content: '';
          position: absolute;
          height: 2px;
          width: 40px;
          bottom: -1px;
          left: 0;
          background-color: $tableBorder;
        }
        .icon {
          position: absolute;
          display: block;
          width: 70px;
          height: 70px;
          z-index: 2;
          background-image: url('../../../../assets/svg/basket/shopping-cart.svg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 25px 25px;
          cursor: pointer;
          &:hover {
            background-position: center;
            background-repeat: no-repeat;
            width: 70px;
            height: 70px;
            background-color: #f2f2f2;
            background-size: 35px 35px;
          }
        }
      }
      &_middle {
        position: relative;
        width: 10%;
        border-right: 2px solid $tableBorder;
        @media screen and (max-width: 540px) {
          height: 70px;
          border: 2px solid $tableBorder;
          border-top: 1px solid $tableBorder;
        }
        .icon {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          z-index: 2;
          background-image: url('../../../../assets/svg/basket/shopping-cart.svg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 25px 25px;
          cursor: pointer;
          top: 0;
          &:hover {
            background-position: center;
            background-repeat: no-repeat;
            background-color: #f2f2f2;
            background-size: 35px 35px;
          }
      }
    }   
  }
}
}
    