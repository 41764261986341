@import "../../style/variables.scss";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 1024px) {
    backdrop-filter: blur(5px);
  }
}

.modal {
  box-sizing: border-box;
  position: fixed;
  top: 20vh;
  max-width: 630px;
  max-height: 550px;
  background-color: $white;
  box-shadow: 2px 2px 8px $hardGrey;
  z-index: 1000;
  animation: slide-down 300ms ease-out forwards;
  border-radius: 5px;
  width: 100%;
  border: 3px solid $hardGrey;
  margin: 0 5px;
  @media screen and (min-width: 661px) {
    width: 40rem;
    left: calc(50% - 20rem);
  }
  @media screen and (max-width: 660px) {
    width: 97%;
    margin: 0 5px;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn_close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 3px solid $hardGrey;
  border-radius: 50%;
  position: absolute;
  top: -39px;
  right: -43px;
  background-color: $lightWhite;
  cursor: pointer;
  background-color: $lightWhite;
  transition: scale 250ms ease-in-out;
  &:hover {
    scale: 1.1;
  }
  svg {
    width: 15px;
    height: 15px;
  }
  @media screen and (max-width: 685px) {
    top: -46px;
    right: -3px;
  }
}
