@import "../../style/variables.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

.titleCategory {
  align-self: flex-start;
  position: absolute;
  top: -28px;
  font-weight: 400;
  text-decoration: underline;
  font-size: 20px;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 10px;
  /* grid-auto-rows: minmax(300px, auto); */
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1575px) {
  .cardGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1350px) {
  .cardGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1075px) {
  .cardGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 825px) {
  .cardGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 578px) {
  .cardGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.buttonMore {
  position: absolute;
  right: 0;
  bottom: -35px;
  border: 1px solid $lightGrey;
  padding: 7px;
  border-top: none;
  color: $hardGrey;
  text-decoration: none;
  &:hover {
    background: $lightWhite;
  }
}

.alert {
  font-size: 26px;
  text-align: center;
  padding: 200px 20px;
}
