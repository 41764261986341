.wrapper {
  width: 320px; 
  height: 435px; 
  position: relative;
  background: red; 
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

