@import "../../../style/variables.scss";

.card {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 290px;
  padding: 7px 15px;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid $lightGrey;
  border-bottom: 1px solid $lightGrey;
  margin: auto;
  margin-top: 15px;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 578px) {
    border-right: none;
  }
  @media screen and (max-width: 540px) {
    height: 400px;
    border-right: none;
  }
  &:hover {
    background: $lightWhite;
  }
  .clickArea {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
}

.lastCard {
  border-right: 0;
}

.wrapperImg {
  display: flex;
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  @media screen and (max-width: 540px) {
    height: 260px;
  }
}

.imgProduct {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;

  margin-bottom: 5px;
}

.title {
  margin-bottom: 8px;
  font-size: 16px;
  height: 52px;
  overflow: hidden;
}

.inStock {
  text-align: end;
  margin-bottom: 3px;
}

.inStockYes {
  color: #008000;
}

.inStockNo {
  color: $lightGrey;
}

.wrapperPrice {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.priceUSD {
	font-family: 'Ruda', sans-serif;
}

.priceUAH {
	font-family: 'Ruda', sans-serif;
  font-weight: 600;
  font-size: $p20;
}
