.share {
  
  &__grid {
    &_header {
      display: grid;
      grid-template-columns: 160px 1fr 140px 150px;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      border-bottom: 1px solid black;
      width: 1350px;
      margin: auto;
      margin-bottom: 25px;
      font-weight: 600;
      @media screen and (max-width: 1415px) {
        grid-template-columns: 160px 845px 140px 120px;
        width: 1245px;
      }
      @media screen and (max-width: 1310px) {
        grid-template-columns: 160px 625px 140px 70px;
        width: 1024px;
      }
    }
    &_header_col1 { 
      grid-area: 1 / 1 / 2 / 2;
      max-width: 160px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1310px) {
        grid-area: 1 / 1 / 2 / 2;
      } 
    }
    &_header_col2 { 
      grid-area: 1 / 2 / 2 / 3;
      max-width: 888px;
      display: flex;
      justify-content: flex-start;
      padding: 0 18px; 
    }
    &_header_col3 { 
      grid-area: 1 / 3 / 2 / 4;
      max-width: 140px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1415px) {
        width: 140px;
      }
      @media screen and (max-width: 1310px) {
        width: 140px;
      } 
    }
    &_header_col4 { 
      grid-area: 1 / 4 / 2 / 5;
      max-width: 150px;
      display: flex;
      justify-content: center;
    }
    
    &_colName {
      font-size: 16px;
      text-transform: uppercase;

    }
    
  }
}