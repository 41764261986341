@import "../../../style/variables.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 10px;
  /* grid-auto-rows: minmax(300px, auto); */
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1575px) {
  .cardGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1350px) {
  .cardGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1075px) {
  .cardGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 825px) {
  .cardGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 578px) {
  .cardGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.alert {
  text-align: center;
  margin-top: 70px;
}
