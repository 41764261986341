@import "../../../style/variables.scss";

.user {
  width: 45px;
  height: 45px;
  fill: $lightWhite;
}

.userOpen {
  width: 45px;
  height: 45px;
  fill: $hardGrey;
}

.list {
  width: 140px;
  z-index: 1;
  border: 1px solid $hardGrey;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 57px;
  right: 0;
  background: white;
  overflow: hidden;
}

.item {
  display: block;
  text-decoration: none;
  color: black;
  &:hover > li {
    background-color: $lightWhite;
  }
  & > li {
    list-style-type: none;
    height: 37px;
    align-content: center;
    line-height: 2.4;
  }
}

.item:not(:last-child) {
  border-bottom: 1px solid $hardGrey;
}

.userWrapper_block {
	position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.userWrapper {
  display: flex;
  position: relative;
  width: 73px;
  height: 73px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    svg{
      fill: $hardGrey;
    }
    background-color: $lightWhite;
  }
  &:active {
    svg{
      fill: $hardGrey;
    }
    background-color: $lightWhite;
  }
}

.userWrapperOpen {
  display: flex;
  position: relative;
  width: 73px;
  height: 73px;
  align-items: center;
  justify-content: center;
  background: $lightWhite;
}

.userLog {
  color: $lightWhite;
  @media screen and (max-width: 1160px) {
    display: none;
  }
  @media screen and (min-width: 1920px) {
    margin-right: -70px;
  }
}

.userRegister {
  color: #f34951;
  text-decoration: none;
  @media screen and (max-width: 1100px) {
    display: none;
  }
  @media screen and (min-width: 1920px) {
		position: absolute;
		top: 20%;
		left: 550%;
  }
}
