@import "../../../../style/variables.scss";

.image {
  width: 45px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid black;
  &:hover {
    background-color: $lightWhite;
  }
}

.wrapper {
	display: flex;
  flex-basis: 77%;
  justify-content: space-between;
	padding-right: 0;
	padding-left: 0;
  border-right: 1px solid black;
  border-left: 1px solid black;
  cursor: pointer;
}

.wrapperFirst {
  margin-right: 110px;
}

.title {
	padding-left: 15px;
}

.lineContainer {
	text-align: right;
}

.counterLine,
.priceLine {
	width: 85%;
	border-bottom: 1px solid black;
	margin-top: 5px;
	margin-bottom: 5px;
}

.priceLine {
	margin-bottom: 5px;
}

.price {
	padding-right: 15px;
	font-family: 'Ruda', sans-serif;
}

.productCounterContainer {
	display: flex;
	align-items: center;
	gap: 12px;
	width: 125px;
	padding-left: 15px;
	margin-bottom: 5px;
}

.garbage,
.garbage_active {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 43px;
  height: 67px;
	cursor: pointer;
}

.garbage {
	& svg {
		width: 23px;
  	height: 23px;
	}
}

.top_block {
	display: block;
}

.bottom_block {
	display: block;
	height: 5px;
}

.garbage_active svg {
	width: 23px;
	height: 31px;
}

.bottom_block_active {
	display: block;
	height: 12px;
}
