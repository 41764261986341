@import "../../style/variables.scss";

.callButton {
  position: fixed;
  right: 40px;
  bottom: 15px;
  width: 55px;
  height: 55px;
  border: none;
  background-color: rgb(188 186 181 / 50%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
  cursor: pointer;
  &:hover {
    scale: 1.05;
  }
  svg {
    fill: $hardGrey;
    width: 30px;
    height: 30px;
  }

   @media screen and (max-width: 1840px) {
    right: 30px;
    bottom: 15px;
  }
 
  @media screen and (max-width: 730px) {
    right: 15px;
    bottom: 15px;
  }
  @media screen and (max-width: 540px) {
  }
}

.callBackModal {
  width: 340px;
  background-color: $white;
  border: 2px solid $hardGrey;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  position: fixed;
  bottom: 60px;
  right: 115px;
  z-index: 10;
  border-radius: 7px;
  &::before {
    content: "";
    position: absolute;
    bottom: -8px;
    right: -10px;
    background-color: #ffffff;
    height: 35px;
    width: 36px;
    clip-path: polygon(62% 29%, 25% 70%, 100% 100%);
    transform: rotate(5deg);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    right: -8px;
    background-color: #333030;
    box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.56);
    height: 2px;
    width: 20px;
    transform: rotate(17deg);
  }
  @media screen and (max-width: 730px) {
    right: 95px;
  }
  @media screen and (max-width: 540px) {
    bottom: 78px;
  }
  @media screen and (max-width: 450px) {
    width: 280px;
    right: 85px;
  }
  @media screen and (max-width: 380px) {
    width: 260px;
  }
  @media screen and (min-width: 1840px) {
    bottom: 62px;
    right: 115px;
  }
}

.callBackModalContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 20px;
	padding-bottom: 20px;
  form {
    display: flex;
    align-items: center;
    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      right: -12px;
      background-color: #333030;
      box-shadow: 1px -1px 3px rgba(0, 0, 0, 0.56);
      height: 2px;
      width: 16px;
      transform: rotate(71deg);
    }
  }
  &_line {
    margin-top: 10px;
    width: 100%;
    height: 2px;
    background-color: $lightGrey;
  }

  .callBackModal_input {
    height: 35px;
    padding: 0 10px;
    border: 2px solid $lightGrey;
    border-radius: 7px;
    background-color: $lightWhite;
		font-family: 'Rubik', sans-serif;
    font-size: 14px;
    outline: none;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    @media screen and (max-width: 450px) {
      width: 150px;
    }
  }
  .callBackModal_btn {
    margin-left: 15px;
    height: 35px;
    border-radius: 7px;
    outline: none;
    border: 2px solid $lightGrey;
    background-color: $lightWhite;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    cursor: pointer;
    &:hover {
      scale: 1.05;
    }
    svg {
      fill: $hardGrey;
      width: 20px;
      height: 30px;
    }
  }
}
