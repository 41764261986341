@import "../../../style/variables.scss";

.breadcrumbs_block {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0;
  font-size: 18px;
	text-transform: none;
  .breadcrumbs_item {
    background-color: $lightGrey;
    height: 25px;
    padding: 1px 7px;
    border-radius: 5px;
    @media screen and (max-width: 640px) {
      padding: 2px 7px;
    }
    @media screen and (max-width: 500px) {
      padding: 3px 7px;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .last_crumb a:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 640px) {
    gap: 5px;
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    gap: 3px;
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    overflow: hidden;
  }
}


