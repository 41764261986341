@import "../../../style/variables.scss";

.menu {
  &__hamburger {
    position: relative;
    margin-right: 15px;
    z-index: 50;
    width: 73px;
    height: 73px;
    display: flex;
    align-items: center;
    
    cursor: pointer;
    @media screen and (max-width: 4000px) {
      justify-content: center;
      left: -22px;
    } 
    @media screen and (max-width: 1420px) {
      margin-right: 15px;
      width: auto;
      justify-content: flex-start;
      left: 0;
    }
    @media screen and (max-width: 540px) {
      margin: 0 15px;
      width: auto;
    }
  }
  &__icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    fill: $lightWhite;
  }
}