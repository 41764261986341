@import "../../../../style/variables.scss";

.card {
	box-sizing: border-box;
  position: relative;
	display: flex;
  flex-direction: column;
  justify-content: space-between;
	padding: 0px 15px 0;
  border-right: 1px solid $lightGrey;
  border-bottom: 1px solid $lightGrey;
  margin: auto;
  margin-top: 15px;
  cursor: pointer;

	.button {
    margin: 0 -16px;
    border-top: 1px solid $lightGrey;
    text-align: center;
    padding: 7px 0px;
    text-transform: uppercase;
    font-size: 18px;
    text-decoration: none;
    &:hover {
      background-color: $lightGrey;
    }
  }
}

.card_link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 290px;
	text-decoration: none;
  
  @media screen and (max-width: 540px) {
    height: 400px;
  }
	
  .clickArea {
    width: 100%;
    height: 90%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .lastCard {
    border-right: 0;
  }

  .wrapperImg {
    display: flex;
    width: 100%;
    height: 200px;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    @media screen and (max-width: 540px) {
      height: 260px;
    }
  }

  .imgProduct {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;

    margin-bottom: 5px;
  }

  .title {
    font-size: 16px;
    height: 56px;
    overflow: hidden;
  }

  .inStock {
    text-align: end;
    margin-bottom: 3px;
  }

  .inStockYes {
    color: #008000;
  }

  .inStockNo {
    color: $lightGrey;
  }

  .wrapperPrice {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 7px;
  }

  .priceUAH {
    font-weight: 600;
    font-size: $p20;
  }

  .heart {
    position: absolute;
    z-index: 12;
    width: 25px;
    height: 25px;
    top: 0;
    right: 5px;
    background-image: url('../../../../assets/svg/black_heart.svg');
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      background-image: url('../../../../assets/svg/basket/garbage_active.svg');  
      width: 35px;
      height: 35px;
      top: -10px;
      right: 0;
    }
  }
}
