@import "../../../../style/variables.scss";

* {
  box-sizing: border-box;
  color: $hardGrey;
}

.logo_navlink {
  display: flex;
  align-items: center;
  gap: 75px;
  margin-right: 20px;
  @media screen and (max-width: 1420px) {
    gap: 0;
  }
  @media screen and (max-width: 1024px) {
    margin-right: 0;
  }
}

.logo {
  background-image: url("../../../../assets/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 175px;
  height: 70px;
  @media screen and (max-width: 1280px) {
    width: 135px;
  @media screen and (max-width: 540px) {
    display: none;
  }
}
}
.nav_links {
  display: flex;
  gap: 40px;
  position: relative;
  align-items: center;
  height: 34px;
  &_wrapper{
    width: 130px;
    position: relative;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(2) {
      width: 200px; 
    }
  }
  @media screen and (max-width: 1330px) {
    gap: 5px;
  @media screen and (max-width: 1024px) {
    display: none;
    gap: 10px;
  }
}
}

.nav_li {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-radius: 7px;
  border: none;
  transition: all 0.2s ease-out;
  padding: 0 23px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 34px;
  @media screen and (max-width: 1280px) {
    padding: 0 5px;
  }
  a {
    text-decoration: none;
    color: $lightWhite;
    font-size: 18px;
    white-space: nowrap;
    @media screen and (max-width: 1280px) {
      font-size: 18px;
    }
  }
}

.nav_li:hover {
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  cursor: pointer;
  a {
    text-decoration: none;
    color: $hardGrey;
    font-size: 20px;
    text-shadow: 1px 1px 2px $hardGrey;
  }
}

.logo_navlink_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .nav_links {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}
