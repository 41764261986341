@import "../../../../../style/variables.scss";

.productItem_quantity {
	display: flex;
	align-items: center;
	gap: 2px;
	width: 100%;
	// &_info {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	width: 68px;
	// 	height: 31px;
	// 	margin-top: 5px;
	// 	border: 1px solid $hardGrey;
	// 	border-radius: 1px;
	// 	overflow: hidden;
	// 	background-color: $lightWhite;
	// 	outline: none;
	// 	font-size: 16px;
	// 	text-transform: uppercase;
	// 	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
	// }
	&_input {
		width: 52px;
		height: 27px;
		padding-top: 2px;
		padding-right: 0;
		padding-left: 0;
		border: 1px solid $hardGrey;
		border-radius: 1px;
		background-color: $lightWhite;
		text-align: center;
		// font-family: "Lato", sans-serif;
		font-family: 'Ruda', sans-serif;
		font-size: 16px;
		text-transform: uppercase;
		outline: none;
		// box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
	}
	.minus {
		width: 27px;
		height: 27px;
		cursor: pointer;
		img {
			width: 27px;
			height: 27px;
		}
		&:active {
			scale: 1.1;
		}
	}
	.plus {
		width: 27px;
		height: 27px;
		cursor: pointer;
		img {
			width: 27px;
			height: 27px;
		}
		&:active {
			scale: 1.1;
		}
	}
	@media screen and (max-width: 1024px) {
		justify-content: center;
	}
}