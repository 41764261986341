@import "../../style/variables.scss";

.container {
	// original is 96vh
  min-height: 93.5vh;
  position: relative;
  padding: 73px 30px 50px 30px;
  margin: auto;
  background-color: #fff;
  max-width: $large1620;
  @media screen and (max-width: 1024px) {
    padding: 73px 15px 50px 15px;
  }
  @media screen and (max-width: 540px) {
    padding: 73px 5px 25px 5px;
  }
}

