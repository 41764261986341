@import "../../../../../../style//variables.scss";

.productItem_price {
	display: flex;
	flex-direction: column;
	p {
		text-align: left;
	}
	span {
		font-family: 'Ruda', sans-serif;
		border-bottom: 1px solid $hardGrey;
		font-size: 20px;
		text-align: left;
	}
	span > span {
		margin-left: 3px;
		border-bottom: none;
		font-size: 16px;
	}
	.quontityText {
		display: inline-block;
		width: 116px;
		border: none;
		font-size: 16px;
		text-align: left;
	}
	@media screen and (max-width: 1024px) {
		align-items: center;
	}
}

.first {
	position: relative;
	right: 9%;
	display: inline-block;
}

.last {
	position: relative;
	left: 21%;
	display: inline-block;
}