@import "../../../../style/variables.scss";

.characteristic_block {
  position: relative;
	max-width: 1142px;
  margin-bottom: 30px;
  font-size: 16px;
  @media screen and (max-width: 800px) {
    margin-bottom: 0px;
  }
}

.devider_container {
	display: inline-block;
}

.productItem_devider {
	width: 85%;
	margin-left: 20px;
	outline: 1px solid #333030;
}

.characteristic_container {
	display: inline-flex;
	align-items: center;
}

.characteristic_line_wrapper {
	display: inline-block;
	width: 603px;
}

.characteristic_line {
  position: relative;
  display: flex;
  align-items: center;
	margin-bottom: 20px;
	@media screen and (max-width: 700px) {
 
  }
}

.characteristic_name {
	overflow-x: hidden;
}

.characteristic_value_container {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
}

.characteristic_value {
	margin-bottom: 20px;
	text-align: left;
}

.characteristic_dotted {
	overflow: hidden;
  letter-spacing: 2px;
  @media screen and (max-width: 700px) {
    letter-spacing: 1px;
  }
  @media screen and (max-width: 600px) {
    letter-spacing: 0.5px;
    font-size: 14px;
  }
}

.characteristic_h {
	margin-bottom: 4px;
  margin-left: 20px;
  font-size: 26px;
  @media screen and (max-width: 510px) {
    margin-left: 0px;
    font-size: 22px;
  }
}

.characteristic_btn_edit {
  position: absolute;
  border: none;
  top: 6px;
  left: 315px;
  width: 27px;
  height: 27px;
  background-color: white;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 510px) {
    top: 30px;
    left: 251px;
  }
}


.characteristic_textarea {
  width: 95%;
  height: 210px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  padding: 10px;
  resize: none;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  &::placeholder {
    font-size: 18px;
    font-family: "Lato", sans-serif;
    padding: 4px;
  }
  @media screen and (max-width: 1200px) {
    width: 93%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    width: 85%;
  }
}

.characteristic_btn_cancel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  top: 10px;
  right: 20px;
  width: 33px;
  height: 33px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 600px) {
    right: 10px;
  }
}

.btn_cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 2px solid $hardGrey;
  border-radius: 50%;
  background-color: $lightWhite;
  svg {
    width: 9px;
    height: 9px;
  }
}

.characteristic_btn_save {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  bottom: 3px;
  right: 20px;
  width: 33px;
  height: 33px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  svg {
    width: 20px;
    height: 25px;
  }
  @media screen and (max-width: 600px) {
    right: 10px;
  }
}

.characteristic_btn_add {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  bottom: -32px;
  left: 290px;
  width: 33px;
  height: 33px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  svg {
    width: 20px;
    height: 25px;
  }
  @media screen and (max-width: 700px) {
    left: 240px;
  }
  @media screen and (max-width: 600px) {
    left: 205px;
  }
  @media screen and (max-width: 510px) {
    left: 140px;
  }
}

.character_btn_edit {
  position: absolute;
  border: none;
  top: 6px;
  left: 242px;
  width: 27px;
  height: 27px;
  background-color: white;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 510px) {
    top: 30px;
    left: 187px;
  }
}

.character_btn_editP {
  position: absolute;
  border: none;
  top: 6px;
  left: 382px;
  width: 27px;
  height: 27px;
  background-color: white;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 510px) {
    top: 30px;
    left: 307px;
  }
}

.character_btn_cancel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  top: 28px;
  left: 623px;
  width: 33px;
  height: 33px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 700px) {
    left: 540px;
  }
  @media screen and (max-width: 600px) {
    left: 465px;
  }
  @media screen and (max-width: 510px) {
    top: 28px;
    left: 315px;
  }
}

.btn_cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 2px solid $hardGrey;
  border-radius: 50%;
  background-color: $lightWhite;
  svg {
    width: 9px;
    height: 9px;
  }
}

.character_btn_save {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  bottom: -30px;
  left: 623px;
  width: 33px;
  height: 33px;
  outline: none;
  border: 2px solid $lightGrey;
  background-color: $lightWhite;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  svg {
    width: 20px;
    height: 25px;
  }
  @media screen and (max-width: 700px) {
    left: 540px;
  }
  @media screen and (max-width: 600px) {
    left: 465px;
  }
  @media screen and (max-width: 510px) {
    left: 315px;
    bottom: -35px;
  }
}

.character_input {
    max-width: 85px;
    height: 32px;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    padding: 10px;
    resize: none;
    outline: none;
    border: 2px solid $lightGrey;
    background-color: $lightWhite;
    border-radius: 7px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.56);
    @media screen and (max-width: 510px) {
      max-width: 75px;
    }
  }

.characteristic_textRead {
  width: 100%;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.anchor {
  position: absolute;
  margin-top: -60px;
}